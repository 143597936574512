<template>
  <FocusTrap>
    <div  id="medical_card">
      <div class="row">
        <div class="col"><h3>Medicals Reporting</h3></div>
        <div class="col">
           <span> <i class="fa fa-fw fa-sort"></i></span>
        </div>
      </div>

      <div class="row">

        <div class="col-md-3" v-for="report in reportings" :key="`${report.medical.id}`" >
          <MedicalVisit :key="`${report.medical.id}`" :id="`doctor_${report.medical.id}`" :reporting="report"/>
        </div>


      </div>

    </div>


  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import MedicalVisit from '../../../components/MedicalVisit'

export default {
  name: 'MeicalReporting',
  store,
  components: { MedicalVisit },
  data(){
    return {
      working_date : moment(),
      date_title : '',
      cards : [],
      reportings : [],
      today_areas : [],
    }
  },
  created () {
    this.$data.working_date = this.$store.state.user.working_date;
  },
  mounted () {
    const self = this;

    // self.$data.reportings = [];
    // self.$store.state.user.doctors.forEach((doctor)=>{
    //   let rep = {"id":0,"date":"","doctor":{"id":0,"name":"","hospital":"","sex":"M","address":"","area_id":1,"qualification":"qualification","speciality":"","class":"","dob":"1900-01-01","dow":"","no_of_visits":0,"last_visit":"2000-01-01","mobile":"","email":"","web":"","whatsapp":"","spouse_name":"","spouse_dob":"","spouse_profession":"","data":"","area":{"id":1,"name":""},"categories":[]},"visit_time":"","visited":false,"not_met_type":"","not_met_remarks":"","lbl_reminder":false,"sample_reminder":false,"cmc_reminder":false,"promoted_products":[]};
    //   rep.doctor = doctor;
    //   self.$data.reportings.push(rep);
    // });

    self.loadDayPlan()();
  },
  methods:{
    loadDayPlan(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      try {

        $('#medical_card').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.today_areas = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/dayplan/emp/${self.$store.state.user.id}/${self.$data.working_date}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#medical_card').unblock();

          if (resp.ok) {

            if (!_.isNull(resp.data)) {

              if(Array.isArray(resp.data.areas)) {
                resp.data.areas.forEach((a) => {
                  self.$data.today_areas.push(a.area_id)
                });
              }

              resp.data.medicals.forEach((myday) => {

                let rep = {
                  'id': 0,
                  'work_date': '',
                  "fieldpeople_id":0,
                  "jointworker_id":0,
                  "doctor_id":0,
                  'doctor': {
                    'id': 0,
                    'name': '',
                    'hospital': '',
                    'sex': 'M',
                    'address': '',
                    'area_id': 1,
                    'qualification': 'qualification',
                    'speciality': '',
                    'class': '',
                    'dob': '1900-01-01',
                    'dow': '',
                    'no_of_visits': 0,
                    'last_visit': '2000-01-01',
                    'mobile': '',
                    'email': '',
                    'web': '',
                    'whatsapp': '',
                    'spouse_name': '',
                    'spouse_dob': '',
                    'spouse_profession': '',
                    'data': '',
                    'area': { 'id': 1, 'name': '' },
                    'categories': []
                  },
                  'visit_time': '',
                  'visited': false,
                  'not_met_type': '',
                  'not_met_remarks': '',
                  'lbl_reminder': false,
                  'sample_reminder': false,
                  'cmc_reminder': false,
                  'promoted_products': []
                };
                rep.medical = myday.medical;
                self.$data.reportings.push(rep);
              });

              // self.loadReportingMedicals();
            }

          } else {
            // swal({ title: "Oops!", text: resp.msg, type: "error" });
            swal({ title: "Oops!", text: "Day Planning not found", type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        }).finally(function () {
          $('#medical_card').unblock();
        });
      }catch (e) {
        alert(e);
      }

    },
    loadReportingMedicals(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      $('#medical_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      let areas =  JSON.stringify(self.$data.today_areas);

      areas = areas.replace('[','');
      areas = areas.replace(']','');


      self.$data.reportings = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/medicals/active/area/${areas}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          // console.log(JSON.stringify(resp.data));
          if( _.isArray(resp.data)){
            resp.data.forEach((medical)=>{
              let rep = {"id":0,"date":"","medical":{"id":0,"name":"","hospital":"","sex":"M","address":"","area_id":1,"qualification":"qualification","speciality":"","class":"","dob":"1900-01-01","dow":"","no_of_visits":0,"last_visit":"2000-01-01","mobile":"","email":"","web":"","whatsapp":"","spouse_name":"","spouse_dob":"","spouse_profession":"","data":"","area":{"id":1,"name":""},"categories":[]},"visit_time":"","visited":false,"not_met_type":"","not_met_remarks":"","lbl_reminder":false,"sample_reminder":false,"cmc_reminder":false,"promoted_products":[]};
              rep.medical = medical;
              self.$data.reportings.push(rep);
            });
          }

        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#medical_card').unblock();
      });

    }
  }

  }
</script>

<style scoped>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
