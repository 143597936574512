<template>
  <FocusTrap>
    <div >
    <div class="card mb-3" style="max-width: 540px; min-height: 150px;">

      <div class="card-header g-0" style="background-color: whitesmoke;">
        <h3 class="card-title">{{reporting.medical.name}}</h3>
      </div>

      <div class="card-body">
            <p class="card-text">{{reporting.medical.qualification}}</p>
            <p class="card-text"><small class="text-muted">{{date_title}}</small></p>
          </div>

      <div class="card-footer row">
        <div class="col">
          <button class="btn btn-warning" @click="show_no_visit_window('myCustomFunction', reporting.medical.id)">Not Meet</button>
        </div>
        <div class="col text-right">
          <button class="btn btn-success" @click="show_yes_visit_window('promoted_products_model', reporting.medical.id)" >Yes, Met</button>
        </div>
      </div>

    </div>

    <FocusTrap>
      <vmodal :name="`${reporting.medical.id}_novisit_window`" transition="nice-modal-fade" :delay="100" :resizable="true" :reset="true" width="70%" height="auto" >
        <MedicalVisitNo  :reporting="reporting"  v-on:medical_novisit_updated="close_no_modal" ></MedicalVisitNo>
      </vmodal>
    </FocusTrap>

      <FocusTrap>
        <vmodal :name="`${reporting.medical.id}_yesvisit_window`" transition="nice-modal-fade" :delay="100" :resizable="true" :reset="true" width="90%" height="auto"  >
          <MedicalVisitYes  :reporting="reporting" :products="products" v-on:medical_visit_yes_updated="close_yes_modal"   ></MedicalVisitYes>
        </vmodal>
      </FocusTrap>

    <!-- The Modal -->
    <div class="modal" id="product_modal" >
      <div class="modal-dialog modal-dialog-scrollable" >
        <div class="modal-content" >

          <!-- Modal Header -->
          <div class="modal-header" style="background-color: whitesmoke;">
            <h2 class="modal-title">Promoted Products</h2>
            <button type="button" class="close" data-dismiss="modal">×</button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">

            <div class="table-responsive table-scrollable">
              <table class="table" >
                <thead>
                  <th style="width: 40px;">#</th>
                  <th>Product</th>
                  <th style="width: 50px;">Qty</th>
                </thead>
                <tbody style="height: 750px">
                  <tr v-for="(item,idx) in products" style="padding: 0px;">
                    <td style="padding: 0px;"><input name="cbProduct" type="checkbox" class="form-control" :id="item.id" :value="item.id"></td>
                    <td style="padding: 0px;">{{item.name}}</td>
                    <td style="padding: 0px;"><input  :id="`txt${item.id}`" :name="`${item.id}`" class="form-control" style="width: 50px;padding: 0px;" type="number" min="0" @change="item_qty_changed" ></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>

          <!-- Modal footer -->
          <div class="modal-footer">
<!--            <button type="button" class="btn btn-danger" @click="get_checked_products" data-dismiss="modal">Update</button>-->
            <button type="button" class="btn btn-danger" @click="get_checked_products">Update</button>
          </div>

        </div>
      </div>
    </div>

  </div>
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import MedicalVisitNo from './MedicalVisitNo';
import MedicalVisitYes from './MedicalVisitYes'

export default {
  name: 'MedicalVisit',
  store,
  components:{
    MedicalVisitNo,
    MedicalVisitYes,
  },
  props:{
    reporting : {
      type :Object,
      default:() => JSON.parse('{"id":0,"date":"","medical":{"id":0,"name":"Medical Name","hospital":"","sex":"M","address":"","area_id":1,"qualification":"qualification","speciality":"","class":"","dob":"1900-01-01","dow":"","no_of_visits":0,"last_visit":"2000-01-01","mobile":"","email":"","web":"","whatsapp":"","spouse_name":"","spouse_dob":"","spouse_profession":"","data":"","area":{"id":1,"name":""},"categories":[]},"visit_time":"","visited":false,"not_met_type":"","not_met_remarks":"","lbl_reminder":false,"sample_reminder":false,"cmc_reminder":false,"promoted_products":[]}')
    }
  },
  data(){
    return {
      userFuncs: {},
      products: [],
      working_date : moment(),
      date_title : '',
      reportings : [],
    }
  },
  created () {
    const self = this;
    self.$data.working_date = self.$store.state.user.working_date;

    self.$data.date_title = moment(self.reporting.medical.last_visit).format('ll');
    this.$store.state.user.products.forEach( (item) => {
      if(item.id > 1) {
        this.$data.products.push(item);
      }
    });

    $("#product_modal").on('shown.bs.modal', function () {
      // alert('The modal is displayed completely!');
    });

    // window.setTimeout(() => {
    //   this.$set(this.userFuncs, 'myCustomFunction', () => {
    //     alert( 'whoohoo, it was added dynamically');
    //   })
    // }, 2000)

  },
  mounted () {
    const self = this;

    // alert(window.innerHeight);
  },
  methods:{
    show_no_visit_window (name, id) {

      this.$modal.show(id +`_novisit_window`);
      // console.log(id);
      // if (this.userFuncs[name]) {
      //   this.userFuncs[name]()
      // } else {
      //   alert(`${name} was not yet defined!`);
      // }
    },
    show_yes_visit_window(name,id){
      this.$modal.show(id +`_yesvisit_window`);
    },
    close_no_modal(){
      this.$modal.hide(this.$props.reporting.medical.id +`_novisit_window`);
    },
    beforeClose(){
    },
    beforeOpen(){
    },
    close_yes_modal(){
      this.$modal.hide( this.$props.reporting.medical.id + '_yesvisit_window');
    },
    item_qty_changed(evt){
      try {
        let qty = parseInt(document.getElementById(evt.target.id).value);
        if(qty > 0) {
          document.getElementById(evt.target.name).checked = true;
        }else{
          document.getElementById(evt.target.name).checked = false;
        }

      }catch (e) {
        alert(e);
      }
    },
    get_checked_products(){
      const self = this;
      try {
        self.reporting.promoted_products = [];
        let checkboxes = document.getElementsByName('cbProduct');
        for (let i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].checked) {

            self.$store.state.user.products.forEach( (item) => {
              if(item.id ==  checkboxes[i].value) {
                self.reporting.promoted_products.push(item);
              }
            });
            //alert(checkboxes[i].value);
          }
        }
      }catch (e) {
        alert(e);
      }
    },
  }
}
</script>

<style scoped>

  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
